import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { PartsStyleObject, SystemStyleObject } from "@chakra-ui/theme-tools";

export type Parts = typeof checkboxAnatomy;

const baseStyleContainer: SystemStyleObject = {
  /**
   * @todo: fix this in Chakra
   */
  _disabled: {
    cursor: "not-allowed",
  },
  gap: "space-12",
};

const baseStyleControl: SystemStyleObject = {
  _checked: {
    _disabled: {
      backgroundColor: "grey-6",
      borderColor: "grey-6",
    },
    backgroundColor: "grey-0",
    background: "grey-0",
    borderColor: "grey-1",
    color: "white",
  },

  _disabled: {
    borderColor: "grey-6",
  },
  _focus: {
    boxShadow: "none",
  },
  // needed to simulate a 24px box
  border: "2px solid",
  borderColor: "grey-0",
  borderRadius: "5px",
  boxSize: "18px",
  minHeight: "18px",
  minWidth: "18px",
  color: "transparent",

  margin: "3px",
  // not in foundations, but needed by the design team
  padding: "space-2",
  transitionDuration: "duration-100",
  transitionProperty: "common",
};

const baseStyleLabel: SystemStyleObject = {
  flex: 1,
  margin: 0,
};

const baseStyle: PartsStyleObject<Parts> = {
  container: baseStyleContainer,
  control: baseStyleControl,
  label: baseStyleLabel,
};

export type Variant = "default" | "outline";

const variantOutline: PartsStyleObject<Parts> = {
  container: {
    _disabled: {
      _hover: {
        borderColor: "inherit",
      },
    },
    _hover: {
      borderColor: "grey-0",
      backgroundColor: "grey-0",
    },
    _invalid: {
      _hover: {
        borderColor: "red-.1",
      },
      borderColor: "red-.1",
    },
    background: "transparent",
    border: "1px solid",
    borderColor: "grey-0",
    borderRadius: "radius-6",
    paddingX: "space-8",
    paddingY: "space-8",
    transitionDuration: "duration-200",
    transitionProperty: "common",
  },
  label: {
    flex: 1,
  },
};

const variants: Record<Variant, PartsStyleObject<Parts>> = {
  default: {},
  outline: variantOutline,
};

const CheckboxTheme = {
  baseStyle,
  parts: checkboxAnatomy.keys,
  variants,
};

export default CheckboxTheme;
