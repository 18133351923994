import { ComponentStyleConfig } from "@chakra-ui/theme";
// import { mode } from "@chakra-ui/theme-tools";

const Button: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: "medium",
  },

  sizes: {
    sm: {
      height: "auto",
      paddingY: "space-8",
      paddingX: "space-24",
      fontSize: "font-15",

    },
    md: {
      height: "auto",
      paddingY: { base: "space-14", md: "space-16" },
      paddingX: "space-24",
      fontSize: "font-15",

    },
  },

  variants: {
    primary: {
      lineHeight: 1.1,
      borderRadius: "full",
      bg: "bg-primary-active",
      color: "white",

      _hover: {
        background: "grey-1",
      },
    },

    //underline
    secondary: {
      lineHeight: 1.1,
      borderRadius: "none",
      bg: "transparent",
      color: "text-primary",
      paddingX: 0,
      paddingY: 0,
      borderBottom: "2px solid",
      paddingBottom: { base: "space-4", md: "space-4" },
    },
    yellow: {
      lineHeight: 1.1,
      borderRadius: "full",
      bg: "yellow-0",
      color: "text-primary",

      _hover: {
        background: "grey-0",
        color: "white",
      },
    },
    lightYellow: {
      lineHeight: 1.1,
      borderRadius: "full",
      bg: "yellow-3",
      color: "text-primary",

      _hover: {
        background: "yellow-5",
        color: "text-primary",
      },
    },
    lightGrey: {
      lineHeight: 1.1,
      borderRadius: "full",
      bg: "greyLight-4",
      color: "text-primary",

      _hover: {
       background: "yellow-5",
      },
    },

    /**
     * We use `link-standard` for buttons that look like links,
     * because in Contentful we already pass a `link` value
     * which maps to a button that doesn't really look like a link.
     */
    link: {
      lineHeight: "inherit",
      paddingX: 0,
      paddingY: 0,
      color: "grey-1",
      _hover: {
        textDecoration: "none",
      },
    },

    "link-navbar": {
      color: "text-primary",
      fontSize: "font-15",
      lineHeight: "short",
      fontWeight: "normal",
    },

    icon: {
      _hover: {
        bg: "greyLight-4",
      },
    },
  },

  defaultProps: {
    variant: "primary",
    size: "md",
  },
};

export default Button;
