/**
 * https://www.figma.com/file/NDYFGMfDaxqqY41C55RDgl/%F0%9F%90%A5-NEW-DESIGN-SYSTEM?node-id=8571%3A34657&t=Zo1x1tq5fDFhbo8L-1
 */

import { PartsStyleObject, SystemStyleObject } from "@chakra-ui/theme-tools";

import { default as CurrentCheckboxTheme, Parts } from "./current";

const baseStyleControl: SystemStyleObject = {
  _checked: {
    _disabled: {
      backgroundColor: "grey-1",
      borderColor: "grey-1",
    },
    _hover: {
      borderColor: "grey-0",
      backgroundColor: "grey-0",
      background: "grey-0",
    },
    backgroundColor: "grey-0",
    background: "grey-0",
    borderColor: "grey-0",
    color: "greyLight-5",
  },
  _disabled: {
    borderColor: "grey-1",
  },
  backgroundColor: "transparent",
  borderColor: "grey-0",
};

const baseStyle: PartsStyleObject<Parts> = {
  ...CurrentCheckboxTheme.baseStyle,
  control: {
    ...CurrentCheckboxTheme.baseStyle.control,
    ...baseStyleControl,
  },
};

const variantOutline: PartsStyleObject<Parts> = {
  ...CurrentCheckboxTheme.variants.outline,
  container: {
    ...CurrentCheckboxTheme.variants.outline.container,
    _disabled: {
      _hover: {
        backgroundColor: "white",
      },
    },
    _hover: {
      backgroundColor: "grey-0",
    },
    _invalid: {
      _hover: {
        borderColor: "orange-800",
      },
      borderColor: "orange-800",
    },
    backgroundColor: "white",
    borderColor: "grey-0",
  },
};

const CheckboxTheme = {
  ...CurrentCheckboxTheme,
  baseStyle: {
    ...CurrentCheckboxTheme.baseStyle,
    ...baseStyle,
  },
  variants: {
    ...CurrentCheckboxTheme.variants,
    outline: variantOutline,
  },
};

export default CheckboxTheme;
