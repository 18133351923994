const RAW_COLORS = {
  white: "#FFFFFF",

  "yellow-0": "#FEE827", //primary yellow
  "yellow-1": "#FFEB85",
  "yellow-2": "#FFF5D2",
  "yellow-3": "#FFF7DB",
  "yellow-4": "#FFFADB", //secondary yellow
  "yellow-5": "#FFFEF5",
  "yellow-6": "#FFF8D2",

  "grey-0": "#25241D", //textPrimaryColor
  "grey-1": "#555249", //textSecondaryColor
  "grey-2": "#DEDCCE",
  "grey-3": "#F0EFE4",
  "grey-4": "#F6F5EF",
  "grey-5": "#FCFBF8",
  "grey-6": "#B1BACC",
  "grey-200": "#EFEFEF",
  "grey-700": "#4F4F4E",

  "greyLight-0": "#7F7B71",
  "greyLight-1": "#B6B4AA", //disable
  "greyLight-2": "#DEDCCE",
  "greyLight-3": "#F0EFE4",
  "greyLight-4": "#F6F5EF",
  "greyLight-5": "#FCFBF8",
  "greyLight-6": "#D9D9D9",

  "blue-0": "#2B464D",
  "blue-1": "#C7E4FF",
  "blue-2": "#E0F0FF",
  "blue-3": "#F2F7FC",
  "blue-4": "#087CBF",
  "blue-200": "#EEF5FB",

  "brown-0": "#F4722A",

  "pink-0": "#9E3D3D", // red-700 on Figma
  "red-0": "#DC4C56",

  "orange-0": "#893815",
  "orange-1": "#FFC9A3",
  "orange-2": "#FFE6C9",
  "orange-800": "#893815",

  "green-0": " #496728",
  "green-1": "#F0FCE4",
};

const colors = {
  ...RAW_COLORS,

  "bg-primary-active": RAW_COLORS["grey-0"],

  "bg-secondary-active": RAW_COLORS["yellow-1"],
  "primary-yellow": RAW_COLORS["yellow-0"],

  "text-primary": RAW_COLORS["grey-0"],
  "text-secondary": RAW_COLORS["grey-1"],
  "text-light": RAW_COLORS["greyLight-0"],
  "bg-input": RAW_COLORS["grey-4"],
  "border-light": RAW_COLORS["grey-3"],
};

export default colors;
