import { createBreakpoints } from "@chakra-ui/theme-tools";

export const breakpointsMapping = {
  xs: "30em",
  sm: "40em",
  md: "60em",
  navbar: "73em",
  lg: "75em",
  xl: "90em",
  "2xl": "96em",
  "3xl" : "100em"
};

const breakpoints = createBreakpoints(breakpointsMapping);

export default breakpoints;
