import { ComponentStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const Text: ComponentStyleConfig = {
  baseStyle: {
    whiteSpace: "pre-wrap",
  },

  variants: {
    body: (props) => ({
      fontSize: { base: "font-16" },
      color: mode("text-primary", "white")(props),
      lineHeight: "short",
      strong: {
        color: mode("text-primary", "white")(props),
      },
    }),
    "body-small": (props) => ({
      fontSize: "font-15",
      color: mode("text-primary", "white")(props),
      lineHeight: { base: "base", lg: "shorter" },
      fontWeight: "normal",
      strong: {
        color: mode("text-primary", "white")(props),
      },
    }),
    cta: {
      fontSize: { base: "font-20" },
      fontWeight: "medium",
      lineHeight: "shorter",
    },
    subtitle: {
      fontFamily: "heading",
      fontSize: {
        base: "font-20",
        lg: "font-28",
      },
      color: "text-primary",
      lineHeight: { base: "short", lg: "shorter"},
      letterSpacing: "-0.02em",
    },
  },

  defaultProps: {},
};

export default Text;
