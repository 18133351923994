import borders from "./borders";
import breakpoints from "./breakpoints";
import colors from "./colors";
import radii from "./radii";
import shadows from "./shadows";
import sizes from "./sizes";
import space from "./space";
import transition from "./transition";
import typography from "./typography";
import zIndices from "./z-index";

const foundations = {
  breakpoints,
  zIndices,
  radii,
  colors,
  ...typography,
  sizes,
  shadows,
  space,
  borders,
  transition,
};

export default foundations;
