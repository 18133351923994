export default {
  baseStyle: {},

  sizes: {
    medium: {
      maxWidth: "48px",
    },
  },

  variants: {
    blue: {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "blue-1",
    },
    grey: {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "grey-0",
    },
    greyLight: {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "greyLight-2",
    },
    dots: {
      height: "30px",
      marginY: "space-24",

      ":before": {
        textAlign: "center",
        content: "'...'",
        display: "block",
        fontSize: "30px",
        lineHeight: 0.5,
        letterSpacing: ".6em",
        color: "text-secondary",
      },
    },
  },

  defaultProps: {},
};
