import Button from "./Button";
import Heading from "./Heading";
import Text from "./Text";
import Container from "./Container";
import Divider from "./Divider";
import Link from "./Link";
import Badge from "./Badge";
import Modal from "./Modal";
import Checkbox from "./Checkbox";

export default {
  Button,
  Checkbox,
  Heading,
  Text,
  Container,
  Divider,
  Link,
  Badge,
  Modal,
};
