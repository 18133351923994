import { extendTheme } from "@chakra-ui/react";

import components from "./components";
import foundations from "./foundations";

const theme = extendTheme({
  components,

  ...foundations,

  styles: {
    global: {
      body: {
        color: "text-primary",
        strong: {
          fontWeight: "medium",
        },
      },
    },
  },

  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
});

export default theme;
