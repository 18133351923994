const withRGBA = (r: number, g: number, b: number) => (a: number) =>
  `rgba(${r}, ${g}, ${b}, ${a})`;

const withOpacity = withRGBA(13, 90, 191);

const shadows = {
  "sm-light": `0 4px 15px 0 ${withOpacity(0.08)}, 0 1px 6px 0 ${withOpacity(
    0.03,
  )}`,
  "sm-light-hover": `0 4px 25px 0 ${withOpacity(
    0.12,
  )}, 0 2px 8px 0 ${withOpacity(0.03)}`,

  outline: "0 0 0 3px rgba(66, 153, 225, 0.6)",

  inner: "inset 0 2px 4px 0 rgba(0,0,0,0.06)",
  none: "none",
};

export const dropShadows = {
  xs:
    "drop-shadow(0px 2px 6px rgba(12, 108, 243, 0.07)) drop-shadow(0px 0px 2px rgba(12, 108, 243, 0.1))",
  sm:
    "drop-shadow(0px 5px 20px rgba(12, 108, 243, 0.08)) drop-shadow(0px 1px 3px rgba(12, 108, 243, 0.08))",
  md:
    "drop-shadow(0px 5px 26px rgba(12, 108, 243, 0.12)) drop-shadow(0px 2px 6px rgba(12, 108, 243, 0.05))",
};

export type Shadows = typeof shadows;

export default shadows;
