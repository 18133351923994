import { ComponentStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const Container: ComponentStyleConfig = {
  baseStyle: (props) => ({
    width: "100%",
    maxWidth: "100%",

    ...(props.colorMode === "dark"
      ? {
          backgroundColor: "grey-0",
        }
      : {}),
  }),

  sizes: {
    full: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingX: { base: 0 },
    },
    block: {
      paddingX: { base: "space-16", md: "space-58", "2xl": 0 },
      paddingY: { base: "space-40", md: "space-60" },
      maxWidth: { md: "1440px", "2xl": "1434px" },

      "> *": {
        maxWidth: { base: "480px", md: "100%" },
      },
    },
  },

  variants: {
    "with-background": (props) => ({
      backgroundColor: mode("ctaBlue-6", "grey-0")(props),
    }),
    "blog-post": {
      maxWidth: "730px",
    },
    heading: {
      paddingX: { base: "0", md: "135px" },
    },
  },

  defaultProps: {},
};

export default Container;
