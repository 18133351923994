export default {
  baseStyle: {},

  sizes: {},

  variants: {
    "video-player": {
      dialog: { maxWidth: { base: "90%", lg: "1200px" }, padding: 0 },
    },
  },

  defaultProps: {},
};
